import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig'

const PublicRoute = () => {

	const { token } = useSelector(state => state.auth)
	const id = parseInt(localStorage.getItem('admin_id'));
	return token ? (id == 1 ? <Navigate to={AUTHENTICATED_ENTRY} /> : <Navigate to='/change-password' />) : <Outlet />
}

export default PublicRoute